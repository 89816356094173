<template>
  <div>
    <div>
      <el-row class="cc">
        <el-col :span="16" :offset="4">
          <div class="top_dh">
            <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span @click="changeNav(1)">磐石产品</span> &gt; <span @click="changeNav(1)">安全网关</span> &gt; <span>安全网关</span></p>
          </div>
          <p class="IntroductionTitle">概述</p>
        </el-col>
      </el-row>

      <el-row class="Introduction cw">
        <el-col :span="16" :offset="4" style="margin-bottom: 20px;">
          <div class="contectBox">
            <div class="contectimg">
              <img src="@/assets/aqwg/a30.jpg" />
            </div>
            <div class="IntroductionDesc" style="overflow:hidden;">
              <h6>磐石安全网关</h6>
              <br>
              <p :style="{height:descShow ? 'auto' : '168px'}">
              磐石安全网关是磐石云桌面解决方案中自主研发的一款软硬件一体产品，是应用程序交付控制器，用于分析应用程序特定的流量，以智能地分发和优化 Web 应用程序的网络流量并确保其安全。磐石安全网关功能涵盖交换、安全性和保护、服务器场优化以及负载平衡，可实现外网云桌面的访问加速和用户体验的提升，并确保其可用性和安全性。既能为用户提供简单安全的访问体验，也能为管理员提供有效的安全控制功能。
              </p>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row class="cc" style="padding-top: 30px;">
        <el-col :span="16" :offset="4">
          <p class="IntroductionTitle">产品功能</p>
        </el-col>
        <el-col :span="16" :offset="4" class="aqa">
          <p>
            磐石安全网关提供了强大的接入安全管理策略和行为控制组件，提供安全接入、负载均衡以及防火墙等功能，通过集成策略管理为多种应用场景提供支持，保障接入、应用和数据的安全，满足包括互联网、跨域网络等不同网络环境安全访问的需求，同时通过配置负载均衡等策略，优化网络性能和可靠性，提高用户体验效果。
          </p>
          <img src="@/assets/aqwg/aq1.png" />
        </el-col>
      </el-row>

  <!--产品功能-->
      <el-row>
          <el-col :span="16" :offset="4" style="padding-bottom:2%;">
            <p class="IntroductionTitle mt">产品特色</p>
            <div style="margin-top:2%;">
              <div v-for="(item,index) in proFunction" :key="index" class="proFunctionBox">
                <div class="proFunctionImg"><img v-lazy="item.imgSrc" width="90%"></div>
                <div class="proFunctionTitle">{{item.title}}</div>
                <div class="proFunctionDesc"><p>{{item.desc}}</p></div>
              </div>
            </div>
          </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "cloudDesktop",
  data(){
    return{
      proFunction:[
        {title:'简便部署',desc:'提供标准配置界面，简化部署，只需将基础信息相关内容进行配置完成后，系统即可正式上线。',imgSrc:require('@/assets/aqwg/a15.png'),},
        {title:'安全接入',desc:'基于互联网以及域用户应用场景，安全网关提供安全接入服务，支持对访问用户进行身份验证，同时支持对访问用户进行身份验证，提供高效的策略管理。',imgSrc:require('@/assets/aqwg/a13.png'),},
        {title:'实时监测',desc:'配合磐石云桌面与控制中枢（控制引擎）实时监听安全网关运行状态，避免因安全网关问题导致用户不能正常访问，发现安全网关运行异常，系统会主动报警。',imgSrc:require('@/assets/aqwg/a17.png'),},
        {title:'宽域访问',desc:'系统提供宽动态网络支持，带宽的支持范围从百兆到万兆不等，系统支持根据用户实例网络情况进行自动优化调整。',imgSrc:require('@/assets/aqwg/a16.png'),},
        {title:'负载均衡',desc:'提升通过互联网进行终端访问的可靠性，系统提供负载均衡功能，对正在访问的关键业务实时监听，提供负载的冗余通道确保访问效率。',imgSrc:require('@/assets/aqwg/a14.png'),},
        {title:'支持堆叠',desc:'在复杂的用户应用场景下，支持多台网关设备堆叠部署，为用户提供更佳的远程访问体验。',imgSrc:require('@/assets/aqwg/a18.png'),},
      ],
      yingyongcj:[
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx1.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx2.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx3.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx4.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx5.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx6.png')}
      ],
      descShow:true,
    }
  },
  created(){
    if (this._isMobile()) {
      // 跳转手机页面
      this.$router.push('cloudDesktopMobile')
    }
  },
  methods:{
    // 判断移动|PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    // 切换导航
    changeNav(navNum){
      this.$store.dispatch('changeShow',navNum)
    },
  }
}
</script>

<style scoped>

/* 通用样式 */
li{
  list-style: none;
}
.clearfix{
  clear: both;
}
/* 导航栏 */
.top_dh{
  margin-bottom: 20px;
}
.top_navigation{
  margin-top: 20px;
}
.top_navigation span{
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
.aqa{
  padding-bottom: 20px;
}
.aqa p{
  font-size:16px;
  color:#888;
  text-indent: 2em;
  text-align: justify;
  display: block;
  width: 85%;
  margin: 20px auto;
  line-height: 28px;
}
.aqa img{
  width: 80%;
  display: block;
  margin: 0 auto;
}
.contectimg{
  width: 39.5%;
  float: left;
  margin-right: 5%;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}
.contectimg img{
  width: 100%;
}
/* 页面样式 */
/*产品概述*/
.cc{background: #f5f5f5;padding-bottom: 30px;}
.cw{background: #fff;}
.rightbtn{text-align: right;}
.mt{margin-top: 3%;}
.contectBox{
  margin-top: 30px;
}
.Introduction{
  position: relative;
}
.IntroductionTitle{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;
}
.IntroductionDesc{
  font-size: 18px;
  color: #888;
  line-height: 31px;
}
.IntroductionDesc h6{
  font-size: 26px;
  line-height: 40px;
  font-weight: bold;
  color: #000;
}
.IntroductionDesc p{
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
  text-align: justify;
}
/*产品架构区块*/
.currencyTitle{
  font-size: 48px;
  font-weight: bold;
  color: #101010;
  text-align: center;
  letter-spacing: 9px;
  padding: 56px 0 58px;
}
.frameworkList{
  text-align: center;
  margin-top:2%;
  padding-top: 2%;
  padding-bottom: 2%;
  /* margin: 0 12.5% 3% 12.5%;
  padding: 44px 0 48px 0; */
}
.frameworkList > img{
  width: 100%;
}
.currencyLittleTit{
  font-size: 26px;
  text-align: left;
  color: #101010;
  padding-left: 5%;
}
/*产品特点*/
.proChara{
  text-align: left;
  width: 97%;
  margin-left: 5.5%;
}
.proChara ul{
  width: 16.666%;
  display: block;
  float: left;
}
.proChara ul:first-child{
  width: 17%;
}
.proChara ul:nth-child(2){
  width: 19%;
}
.proChara ul:nth-child(3){
  width: 15.6%;
}
.proChara ul:nth-child(6){
  width: 14%;
}
.proChara ul:nth-child(5){
  width: 14.1%;
}

.proChara li{
  list-style: disc;
  line-height: 24px;
  padding-right: 16%;
  text-align: justify;
  color: #888;
}
/*产品功能*/
.proFunctionBox{
  background-color: #fff;
  width: 33.333%;
  float: left;
  padding-top: 3%;
  padding-bottom: 2%;
}
.proFunctionBox img{
  width: 84px;
  height: 80px;
}
.proFunctionTitle{
  font-size: 24px;
  color: #101010;
  line-height: 53px;
  text-align: center;
}
.proFunctionDesc p{
  font-size: 16px;
  color: #888;
  line-height: 24px;
  height: 120px;
  padding: 5% 20%;
  text-align: justify;
  white-space: break-spaces;
}
.proFunctionImg{
  text-align: center;
}

@media screen and (min-width: 1000px){
  
}
@media screen and (min-width: 1450px){
  .proChara li{line-height: 30px;}
}
@media screen and (min-width: 1560px){
}
</style>